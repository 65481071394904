<template>
  <div class="mt-[22px] form-device">
    <a-form
      ref="formModal"
      :model="formState"
      label-align="left"
      :rules="rules"
      :validate-trigger="['change', 'blur']"
      @finish="onFinish"
      class="h-[632px] flex flex-col justify-between"
    >
      <div>
        <a-form-item name="name">
          <span class="text-sm text-ems-gray500 block mb-1">{{
            t('device.name')
          }}</span>
          <cds-input
            :disabled="action === ACTION.VIEW"
            class="w-100 ant-input-sm"
            v-model:value="formState.name"
            :show-count="false"
            size="small"
            :placeholder="t('device.name')"
          />
        </a-form-item>
        <a-form-item name="code">
          <span class="text-sm text-ems-gray500 block mb-1">{{
            t('device.code')
          }}</span>
          <cds-input
            :disabled="action === ACTION.VIEW"
            class="w-100 ant-input-sm"
            v-model:value="formState.code"
            :show-count="false"
            size="small"
            :placeholder="t('device.code')"
          />
        </a-form-item>

        <a-form-item name="type">
          <span class="text-sm text-ems-gray500 block mb-1">{{
            t('device.type')
          }}</span>
          <a-select
            :options="typeOptions"
            :label-prop="'label'"
            :value-prop="'value'"
            v-model:value="formState.type"
            :placeholder="t('device.type')"
            class="ant-input-sm mr-4"
            type="text"
            :disabled="action === ACTION.VIEW"
          />
        </a-form-item>

        <a-form-item name="user">
          <span class="text-sm text-ems-gray500 block mb-1">{{
            t('device.user')
          }}</span>
          <a-select
            :options="userOptions"
            :label-prop="'label'"
            :value-prop="'value'"
            v-model:value="formState.user"
            :placeholder="t('device.user')"
            class="ant-input-sm mr-4"
            type="text"
            :disabled="action === ACTION.VIEW"
          />
        </a-form-item>
      </div>

      <div class="w-100 flex justify-center">
        <ClipButton
          v-if="action !== ACTION.VIEW"
          type="primary"
          size="small"
          style="height: fit-content"
          class="w-[120px]"
          click="saveData"
        >
          <span class="text-sm text-ems-white">{{
            action === ACTION.CREATE ? $t('common.add') : $t('common.edit')
          }}</span>
        </ClipButton>
      </div>
    </a-form>
  </div>
</template>
<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import ClipButton from '@/components/buttons/ClipButton.vue';
import Tag from '@/components/tags/Tag';
import { ACTION } from '@/config/Constant.js';
import { useForm } from 'ant-design-vue/es/form';
import { maxlength, patternRule, required } from '@/util/validation';

const { t } = useI18n();
const action = computed(() => state.deviceManagement.action);
const { state } = useStore();
const userOptions = computed(() => state.user.list);
const typeOptions = [
  {
    label: 'loại 1',
    value: '1',
  },
  {
    label: 'loại 2',
    value: '2',
  },
];
const rules = ref({
  name: [
    patternRule(/^[0-9A-Za-z]*$/, t('device.name')),
    maxlength(255, t('device.name')),
  ],
  code: [
    patternRule(/^[0-9A-Za-z]*$/, t('device.code')),
    maxlength(255, t('device.code')),
  ],
  type: [],
  user: [required(t('device.user'))],
});
const { validate } = useForm(props.formState, rules);
const onFinish = () => {
  console.log('xxx');
};
const props = defineProps({
  formState: {
    default: {},
  },
});
</script>
<style lang="scss">
.form-device .ant-select {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 67, 67, var(--tw-bg-opacity));
  border-style: none;
  height: 38px;
  width: 100%;
}
.form-device .ant-select-selector {
  background-color: #434343 !important;
  border: none !important;
  border-radius: 0 !important;
  padding: 0 !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.form-device .ant-select-selector .ant-select-selection-search {
  background-color: #434343 !important;
}
.form-device .ant-select-selector .ant-select-selection-placeholder {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #7b7b7b !important;
}
.form-device .ant-select-selector .ant-select-selection-item {
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(233, 233, 233, var(--tw-text-opacity));
}
.form-device .ant-input {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 67, 67, var(--tw-bg-opacity));
  font-size: 1rem;
  line-height: 1.5rem;
  padding-left: 11px;
  padding-right: 11px;
  padding-top: 5px;
  padding-bottom: 5px;
  --tw-text-opacity: 1;
  color: rgba(233, 233, 233, var(--tw-text-opacity));
  border: none !important;
}
.form-device .ant-input::placeholder {
  --tw-text-opacity: 1;
  color: rgba(123, 123, 123, var(--tw-text-opacity));
}
.form-device .ant-checkbox-inner {
  background-color: transparent !important;
  border: 1px solid #b4b4b4;
}
</style>
